import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation,
  Redirect
} from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';

import Home from './pages/Home';

import Blog from './pages/Blog';
import Societe from './pages/Societe';
import BlogPostRisk from './pages/BlogPosts/BlogPostRisk';
import BlogPostQuicksight from './pages/BlogPosts/BlogPostQuicksight';
import BlogPostApi from './pages/BlogPosts/BlogPostApi';
import BlogPostBFR from './pages/BlogPosts/BlogPostBFR';
import BlogPostCashpooling from './pages/BlogPosts/BlogPostCashpooling';
import BlogPostErreursTreso from './pages/BlogPosts/BlogPostErreursTreso';
import BlogPostRappro from './pages/BlogPosts/BlogPostRappro';
import BlogPostConformite from './pages/BlogPosts/BlogPostConformite';
import BlogPostOpenBanking from './pages/BlogPosts/BlogPostOpenBanking';
import BlogPostPlacement from './pages/BlogPosts/BlogPostPlacement';
import BlogPostStartup from './pages/BlogPosts/BlogPostStartup';
import BlogPostPasteur from './pages/BlogPosts/BlogPostPasteur';
import BlogPostAmaury from './pages/BlogPosts/BlogPostAmaury';
import BlogPostDélaisPaiement from './pages/BlogPosts/BlogPostDélaisPaiement';
import BlogPostKaufman from './pages/BlogPosts/BlogPostKaufman';
import BlogPostGestionTreso from './pages/BlogPosts/BlogPostGestionTreso';
import BlogPostFtp from './pages/BlogPosts/BlogPostFtp';
import BlogPostISO from './pages/BlogPosts/BlogPostISO';
import BlogPostAchat from './pages/BlogPosts/BlogPostAchat';
import BlogPostTreso from './pages/BlogPosts/BlogPostTreso';
import BlogPost4yeux from './pages/BlogPosts/BlogPost4yeux';
import BlogPostSaas from './pages/BlogPosts/BlogPostSaas';
import BlogPostSepa from './pages/BlogPosts/BlogPostSepa';
import BlogPostEbics from './pages/BlogPosts/BlogPostEbics';
import BlogPostGPI from './pages/BlogPosts/BlogPostGPI';
import BlogPostFOVI from './pages/BlogPosts/BlogPostFOVI';
import BlogPostUniverswift from './pages/BlogPosts/BlogPostUniverswift';
import BlogPostEbam from './pages/BlogPosts/BlogPostEbam';
import BlogPostSisID from './pages/BlogPosts/BlogPostSisID';
import BlogPostOC from './pages/BlogPosts/BlogPostOC';
import BlogPostWebsite from './pages/BlogPosts/BlogPostWebsite';
import Contact from './pages/Contact';
import OpenCash from './pages/OpenCash';
import Cubicus from './pages/Cubicus';
import Hepatus from './pages/Hepatus';
import Ciliaris from './pages/Ciliaris';
import Exodon from './pages/Exodon';
import Adioryx from './pages/Adioryx';
import Privacy from './pages/Privacy';
import MdpDuJour from './pages/MdpDuJour';  
import PageNotFound from './pages/PageNotFound';

function App() {

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 750,
      easing: 'ease-out-quart',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';;
    window.scroll({ top: 0 });;
    document.querySelector('html').style.scrollBehavior = '';;
  }, [location.pathname]); // triggered on route change

  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/blog">
        <Blog />
      </Route>

      <Route exact path="/blog-post-financial-risks">
        <BlogPostRisk />
      </Route>

      <Route exact path="/blog-post-quicksight">
        <BlogPostQuicksight />
      </Route>

      <Route exact path="/blog-post-api">
        <BlogPostApi />
      </Route>

      <Route exact path="/blog-post-bfr">
        <BlogPostBFR />
      </Route>

      <Route exact path="/blog-post-cashpooling">
        <BlogPostCashpooling />
      </Route>

      <Route exact path="/blog-post-erreurs-gestion-de-treso">
        <BlogPostErreursTreso />
      </Route>

      <Route exact path="/le-rapprochement-bancaire-dans-la-gestion-financiere-definition-objectifs-methodologie">
        <BlogPostRappro />
      </Route>

      <Route exact path="/defis-conformite-reglementaire-services-financiers-2024">
        <BlogPostConformite />
      </Route>

      <Route exact path="/blog-post-open-banking">
        <BlogPostOpenBanking />
      </Route>

      <Route path="/blog-post-conseils-pour-bien-investir-votre-excédent-de-trésorerie">
        <BlogPostPlacement />
      </Route>
      
      <Route path="/blog-post-startup">
        <BlogPostStartup />
      </Route>

      <Route path="/blog-temoignage-institut-pasteur">
        <BlogPostPasteur />
      </Route>

      <Route path="/blog-temoignage-amaury">
        <BlogPostAmaury />
      </Route>

      <Route path="/blog-post-delais-de-paiement">
        <BlogPostDélaisPaiement />
      </Route>

      <Route path="/blog-temoignage-kaufman&broad">
        <BlogPostKaufman />
      </Route>

      <Route path="/blog-post-gestion-treso">
        <BlogPostGestionTreso />
      </Route>

      <Route path="/blog-post-ftp">
        <BlogPostFtp />
      </Route>

      <Route path="/blog-post-iso20022">
        <BlogPostISO />
      </Route>

      <Route path="/blog-post-parcours-achat-client">
        <BlogPostAchat />
      </Route>

      <Route path="/blog-post-treso">
        <BlogPostTreso />
      </Route>

      <Route path="/blog-post-4yeux">
        <BlogPost4yeux />
      </Route>

      <Route path="/blog-post-saas">
        <BlogPostSaas />
      </Route>

      <Route path="/blog-post-sepa">
        <BlogPostSepa />
      </Route>

      <Route path="/blog-post-ebics">
        <BlogPostEbics />
      </Route>

      <Route path="/blog-post-swiftgpi">
        <BlogPostGPI />
      </Route>

      <Route path="/blog-post-fovi">
        <BlogPostFOVI />
      </Route>

      <Route path="/blog-post-universwiftnet">
        <BlogPostUniverswift />
      </Route>

      <Route path="/blog-post-ebam">
        <BlogPostEbam />
      </Route>

      <Route path="/blog-post-sisid">
        <BlogPostSisID />
      </Route>

      <Route path="/blog-post-opencash">
        <BlogPostOC />
      </Route>

      <Route path="/blog-post-website">
        <BlogPostWebsite />
      </Route>

      <Route path="/societe">
        <Societe />
      </Route>

      <Route path="/opencash">
        <OpenCash />
      </Route>

      <Route path="/cubicus">
        <Cubicus />
      </Route>

      <Route path="/hepatus">
        <Hepatus />
      </Route>

      <Route path="/ciliaris">
        <Ciliaris />
      </Route>
      <Route path="/exodon">
        <Exodon />
      </Route>

      <Route path="/adioryx">
        <Adioryx />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>

      <Route path="/privacy">
        <Privacy />
      </Route>

      <Route path="/mot-de-passe-du-jour">
        <MdpDuJour />
      </Route>


      <Redirect from='/index.php/14-logiciels-et-services' to="/#logiciels"/>
      <Redirect from='/index.php/solutions' to="/#logiciels"/>
      <Redirect from='/index.php/solutions/saas' to="/#logiciels"/>
      <Redirect from='/index.php/solutions/cubicus' to="/cubicus">
        <Cubicus />
      </Redirect>
      <Redirect from='/index.php/solutions/ciliaris' to="/ciliaris">
        <Ciliaris />
      </Redirect>
      <Redirect from='/index.php/solutions/adioryx' to="/adioryx">
        <Adioryx />
      </Redirect>
      <Redirect from='/index.php/solutions/hepatus' to="/hepatus">
        <Hepatus />
      </Redirect>
      <Redirect from='/index.php/solutions/exodon' to="/exodon">
        <Exodon />
      </Redirect>
      <Redirect from='/index.php/en/' to="/blog-post-sisid">
        <BlogPostSisID />
      </Redirect>
      <Redirect from='/index.php/presse/actualites' to="/blog">
        <Blog />
      </Redirect>
      <Redirect from='/index.php/presse/presse' to="/blog">
        <Blog />
      </Redirect>
      <Redirect from='index.php/contact/nouscontacter' to="/contact">
        <Contact />
      </Redirect>
      <Redirect from='/index.php/support' to="/contact">
        <Contact />
      </Redirect>
      <Redirect from='/index.php/references/clients' to="/#clients"/>
      <Redirect from='/index.php/references/partenaires' to="/societe/#partners"/>
      <Redirect from='/index.php/references/revendeurs' to="/societe/#partners"/>

      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
}
export default App;
