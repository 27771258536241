import React from 'react';
import { Link } from 'react-router-dom';
function News() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h2 className="h2 mb-4 font-libre" data-aos="fade-up">Actualités</h2>
          </div>

          {/* Articles list */}
          <div className="max-w-sm mx-auto md:max-w-none">
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
              {/* 1st article */}
              <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-financial-risks" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/risks.jpg').default} alt="financial risks"/>
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-financial-risks" className="hover:text-rose-700 transition duration-150 ease-in-out">Gestion de risques financiers pour les entreprises : quelles solutions ?</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">La trésorerie désigne les ressources financières dont dispose l’entreprise et constitue un indicateur clé de sa santé financière. Elle peut également être définie comme étant la différence entre le fonds de roulement (FR) et le besoin en fonds de roulement (BFR)</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">24 Janvier, 2025</span>
                  </div>
                </footer>
              </article>
              <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-quicksight" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/quicksight.jpg').default} alt="Outil BI"/>
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-quicksight" className="hover:text-rose-700 transition duration-150 ease-in-out">QuickSight : Le nouveau module d’OpenCash pour l’analyse stratégique</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Dans un contexte économique en constante évolution, il est primordial pour les entreprises de maintenir leur gestion de trésorerie en adoptant de nouvelles technologies, telles que l’intelligence artificielle, le big data et la blockchain.  Parmi ces technologies, on trouve la Business Intelligence (BI), élément crucial offrant des outils d’analyse et de visualisation des données qui facilite la prise de décision stratégique.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">09 Décembre, 2024</span>
                  </div>
                </footer>
              </article>
              <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-api" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/api.jpg').default} alt="Les APIs : votre allié pour une gestion de trésorerie"/>
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-api" className="hover:text-rose-700 transition duration-150 ease-in-out">Les APIs : votre allié pour une gestion de trésorerie agile et efficace</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Vous en avez sûrement assez de passer des heures et des heures à rapprocher vos relevés bancaires ou à calculer vos prévisions de trésorerie ? Les APIs sont là pour révolutionner votre quotidien et vous faire gagner un temps précieux. Mais qu’est-ce que c’est exactement, et comment peuvent-elles vous aider à optimiser votre gestion de trésorerie ?</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">19 Août, 2024</span>
                  </div>
                </footer>
              </article>
              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-bfr" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/bfr.jpg').default} alt="Le cash pooling dans la gestion de trésorerie" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-bfr" className="hover:text-rose-700 transition duration-150 ease-in-out">PME : Plus jamais à court d'argent ! Maîtrisez votre BFR en 5 étapes</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Vous vous êtes déjà senti pris au piège dans une spirale financière ? Les factures s’empilent, les clients tardent à payer, et vous avez l’impression de courir après votre argent ? </p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">07 Août, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-cashpooling" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/cash_pooling.jpg').default} alt="Le cash pooling dans la gestion de trésorerie" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-cashpooling" className="hover:text-rose-700 transition duration-150 ease-in-out">Le Cash Pooling dans la gestion de trésorerie des multinationales</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Dans un monde économique de plus en plus globalisé, où les entreprises opèrent à travers les frontières, la gestion efficace de la trésorerie est devenue un enjeu stratégique. La centralisation des flux, ou cash pooling, une technique de gestion financière éprouvée, offre aux multinationales un moyen de centraliser leurs liquidités, d’optimiser leurs opérations et de renforcer leur position concurrentielle.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">31 Juillet, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-erreurs-gestion-de-treso" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/erreurs_tréso.jpg').default}  alt="News 01" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-erreurs-gestion-de-treso" className="hover:text-rose-700 transition duration-150 ease-in-out">Gestion de trésorerie : 7 erreurs à ne pas commettre</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">La gestion de trésorerie est essentielle pour la stabilité financière d’une entreprise. Pourtant, de nombreuses entreprises commettent des erreurs qui compromettent leur santé financière. Voici quelques erreurs courantes et comment les éviter, afin de garantir une gestion optimale de votre trésorerie.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">24 Juillet, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/le-rapprochement-bancaire-dans-la-gestion-financiere-definition-objectifs-methodologie" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/rappro.jpg').default}  alt="News 01" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/le-rapprochement-bancaire-dans-la-gestion-financiere-definition-objectifs-methodologie" className="hover:text-rose-700 transition duration-150 ease-in-out">Le rapprochement bancaire : pilier de la gestion financière précise pour les entreprises</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Le rapprochement bancaire est une pierre angulaire de la gestion financière des entreprises. Il garantit la précision des comptes en comparant les enregistrements comptables internes avec les relevés bancaires externes.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">18 Juillet, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/defis-conformite-reglementaire-services-financiers-2024" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/defis.jpg').default}  alt="News 01" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/defis-conformite-reglementaire-services-financiers-2024" className="hover:text-rose-700 transition duration-150 ease-in-out">Les défis de la conformité réglementaire pour les services financiers en 2024</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">La conformité réglementaire dans le secteur financier désigne l'ensemble des processus et des politiques qu'une institution financière doit mettre en place pour respecter les lois, les règlements, et les normes applicables à ses opérations. Cela inclut la protection des clients, la lutte contre le blanchiment d'argent et le financement du terrorisme, ainsi que la garantie de la sécurité des informations sensibles.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">16 Juillet, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-open-banking" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/open_banking.jpg').default}  alt="News 01" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-open-banking" className="hover:text-rose-700 transition duration-150 ease-in-out">L'Open Banking : transformations, avantages et défis d'une révolution financière</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">L’Open Banking révolutionne le secteur financier en ouvrant l’accès aux données bancaires des clients à des tiers via des APIs sécurisées. Cette initiative vise principalement à améliorer l’expérience client et à augmenter la concurrence en stimulant l’innovation.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">04 Juillet, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-conseils-pour-bien-investir-votre-excédent-de-trésorerie" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/placement.png').default}  alt="News 01" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-conseils-pour-bien-investir-votre-excédent-de-trésorerie" className="hover:text-rose-700 transition duration-150 ease-in-out">Les secrets d'un placement bancaire réussi pour votre trésorerie excédentaire</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Dans un monde économique en constante évolution, gérer efficacement l’excédent de trésorerie de votre entreprise n’est pas seulement important, c’est essentiel. Cela vous permet de saisir les opportunités, de soutenir votre stratégie d'affaires et de naviguer sereinement en période d’incertitude financière. Mais comment transformer cette liquidité en un véritable atout ?</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">02 Juillet, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-startup" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/startup.jpg').default}  alt="News 01" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-startup" className="hover:text-rose-700 transition duration-150 ease-in-out">Guide Complet : les secrets du pilotage financier pour les startups</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Dans la course vers le succès, la gestion financière est un pilier essentiel pour les start-ups. Cela va de déterminer le bon financement à équilibrer un budget ambitieux tout en assurant une croissance stable.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">25 Juin, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-temoignage-institut-pasteur" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/institut.jpg').default}  alt="News 01" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-temoignage-institut-pasteur" className="hover:text-rose-700 transition duration-150 ease-in-out">Succès à l’Institut Pasteur : Comment Cubicus redéfinit la Gestion de Trésorerie ?</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">L’institut Pasteur est une fondation française privée à but non-lucratif, reconnue d’utilité publique, qui se consacre à la recherche fondamentale, l’enseignement et la veille sanitaire.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">02 Mai, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-temoignage-amaury" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/Amaury.jpg').default}  alt="News 01" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-temoignage-amaury" className="hover:text-rose-700 transition duration-150 ease-in-out">Choix Stratégique : Comment Cubicus a répondu aux attentes du Groupe Amaury ?</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Amaury domine le domaine du sport avec sa marque phare, "L'Équipe", présente à la fois sur papier, en ligne, sur mobile et à la télévision avec la chaîne du même nom.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">30 Avril, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-delais-de-paiement" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/délais_paiement.png').default}  alt="News 01" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-delais-de-paiement" className="hover:text-rose-700 transition duration-150 ease-in-out">Comment les délais de paiement affectent-ils votre trésorerie ?</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Quelques conseils pour les optimiser.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">25 Avril, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-temoignage-kaufman&broad" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/kaufman.jpg').default}  alt="News 01" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-temoignage-kaufman&broad" className="hover:text-rose-700 transition duration-150 ease-in-out">Comment Cubicus a renforcé l’efficacité opérationnelle de Kaufman & Broad ?</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">La Flexibilité, la Facilité d’utilisation et un Engagement inégalé en lumière.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">23 Avril, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-gestion-treso" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/gestion-treso.jpg').default}  alt="News 02" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-gestion-treso" className="hover:text-rose-700 transition duration-150 ease-in-out">Pouvoir de la trésorerie : Comment elle détermine le succès de l’entreprise ? </Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Définition, impact, défis et pratiques à adopter</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">12 Mars, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-ftp" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/FTP.jpg').default}  alt="News 02" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-ftp" className="hover:text-rose-700 transition duration-150 ease-in-out">Les Protocoles de Transfert de Fichiers</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">FTP, FTPS et SFTP expliqués en détail</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">27 Février, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-iso20022" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/iso.jpg').default}  alt="News 02" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-iso20022" className="hover:text-rose-700 transition duration-150 ease-in-out">La migration vers la norme ISO 20022</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Comment les départements financiers peuvent saisir l’opportunité et surmonter les défis ? </p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">27 Février, 2024</span>
                  </div>
                </footer>
              </article> */}

               {/* 2nd article */}
               {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-parcours-achat-client" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/achat.jpg').default}  alt="parcours achat client chez CPI" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-parcours-achat-client" className="hover:text-rose-700 transition duration-150 ease-in-out">Le parcours d’achat client chez CPI</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Une approche personnalisée de la gestion financière.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">13 Février, 2024</span>
                  </div>
                </footer>
              </article> */}

              {/* 3rd article */}
              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-treso" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/treso.jpg').default}  alt="Validation 4 yeux, la puissance de la validation des 4 yeux, double validation 4 yeux, défis de la validation par 4 yeux, histoire de la validation par 4 yeux" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-treso" className="hover:text-rose-700 transition duration-150 ease-in-out">L'automatisation dans la gestion de la trésorerie</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Trésoriers, Professionnels de la finance. Maîtrisez la transformation digitale de la fonction trésorerie et devenez un acteur de l’évolution financière de votre entreprise !</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">23 Janvier, 2024</span>
                  </div>
                </footer>
              </article>
              
              <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-sepa" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/sepa.jpg').default}  alt="News 02" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-sepa" className="hover:text-rose-700 transition duration-150 ease-in-out">Qu’est-ce qu’un mandat de prélèvement SEPA ?</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Un prélèvement SEPA, ou “Single Euro Payments Area”, est un moyen de paiement électronique qui permet de réaliser des transactions bancaires en euros au sein de la zone SEPA.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">19 Octobre, 2023</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-ebics" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/ebics.jpg').default}  alt="News 03" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">
                    <Link to="/blog-post-ebics" className="hover:text-rose-700 transition duration-150 ease-in-out">Qu’est-ce que le protocole EBICS ?</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Solution sécurisée qui vous permet d’automatiser la collecte de vos relevés bancaires et la transmission de vos ordres de paiement et d’encaissement.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">04 Octobre, 2023</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-swiftgpi" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/swiftgpi.jpg').default}  alt="News 01" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">
                    <Link to="/blog-post-swiftgpi" className="hover:text-rose-700 transition duration-150 ease-in-out">Le service GPI de SWIFT : le nouveau standard pour les paiements internationaux </Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Qu’est-ce que GPI for corporates de SWIFT ?</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">28 Avril, 2023</span>
                  </div>
                </footer>
              </article> */}

              {/* 3rd article */}
              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-fovi" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/fovi-header.jpg').default}  alt="News 02" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">  
                    <Link to="/blog-post-fovi" className="hover:text-rose-700 transition duration-150 ease-in-out">Qu’est-ce que l’escroquerie aux faux ordres de virement (FOVI) ? </Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">La fraude aux faux ordres de virement, une menace majeure pour les entreprises.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">28 Avril, 2023</span>
                  </div>
                </footer>
              </article> */}


              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="100">
                <header>
                  <Link to="/blog-post-universwiftnet" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/swiftnet.jpg').default}  alt="News 03" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">
                    <Link to="/blog-post-universwiftnet" className="hover:text-rose-700 transition duration-150 ease-in-out">Universwiftnet – 9 juin 2022, Paris.</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Le Forum de la relation Banque-Entreprise et de ses technologies depuis 2004.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">24 Juin, 2022</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="200">
                <header>
                  <Link className="block mb-6" to="/blog-post-sisid">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/partenariat.jpg').default} width="352" height="198" alt="News 02" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">
                    <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="/blog-post-sisid">CPI et Sis ID unissent leurs forces pour lutter contre la fraude au virement bancaire</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400">Les fraudes bancaires sont de plus en plus violentes et perfectionnées. Avec la crise sanitaire, les entreprises ont dû revoir leur organisation, laissant parfois des failles dans leurs systèmes d’informations. Celles-ci les rendent plus vulnérables face à la fraude.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">10 Juin, 2021</span>
                  </div>
                </footer>
              </article> */}

              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="300">
                <header>
                  <Link className="block mb-6" to="/blog-post-ebam">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/blogIntro.jpg').default} width="352" height="198" alt="News 01" />
                    </figure>
                  </Link>

                  <h3 className="h4 mb-2">
                    <Link className="hover:text-rose-700 transition duration-150 ease-in-out" to="/blog-post-ebam">eBAM : SÉCURISATION ET DIGITALISATION DES POUVOIRS BANCAIRES</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400">eBAM ou Electronic Bank Account Management, désigne la gestion dématérialisée des comptes bancaires et des différentes opérations qui y sont associées.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">15 Juin, 2020</span>
                  </div>
                </footer>
              </article> */}
              
              {/* <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="400">
                <header>
                  <Link to="/blog-post-website" className="block mb-6">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={require('../images/OpenCash/newwebsite.jpg').default}  alt="News 03" />
                    </figure>
                  </Link>
                  <h3 className="h4 mb-2">
                    <Link to="/blog-post-website" className="hover:text-rose-700 transition duration-150 ease-in-out">CPI change son site internet</Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-600 dark:text-gray-400 grow">Plus simple et plus proche de vous, notre nouveau site vous  aide à  mieux  trouver le bon outil  pour sécuriser et simplifier vos flux bancaires.</p>
                <footer className="flex items-center mt-4">
                  <div>
                    <span className="text-rose-700">07 Mars, 2022</span>
                  </div>
                </footer>
              </article> */}
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;
